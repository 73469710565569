.ReportFileSystemWrapper {
  min-height: 100vh;
  max-width: 100%;
  display: flex;
  justify-content: center;

  .ReportFileSystem {
    width: 100%;
    padding: 20px;
    margin-top: 70px;

    .FileCardContainer {
      max-width: 100%;
      display: flex;
      justify-content: center;

      .FileCardMainWrapper {
        display: flex;
        width: 90%;
        .checkBoxDiv {
          display: flex;
          justify-content: space-between;
          margin-top: 10px;
          margin-bottom: 0;
        }

        .FileCardWrapperForGrid {
          max-width: 100%;
          display: flex;
          // justify-content: center;
          flex-wrap: wrap;
          gap: 15px;

          .FileCardImgWrapper {
            display: flex;
            justify-content: center;
          }
          .FileCardTitle {
            max-width: 250px;
            display: flex;
            justify-content: center;
            font-size: small;
            margin-bottom: 4px;
            margin-bottom: 6px;
            height: 70px;
          }
          .FileCardTitle:hover .tooltiptext {
            visibility: visible;
          }
          .GridDateSpan {
            display: flex;
            justify-content: center;
          }
          .GridFileStatusWrapper {
            display: flex;
            justify-content: space-between;
            max-height: 30px;
          }
        }
        .ErrorStatusSpan {
          background-color: crimson;
          padding: 5px;
          border-radius: 5px;
          font-size: small;
        }
        .PendingStatusSpan {
          background-color: #ffa500;
          padding: 5px;
          border-radius: 5px;
          font-size: small;
        }
        .SuccessStatusSpan {
          background-color: #0cce6b;
          padding: 5px;
          border-radius: 5px;
          font-size: small;
        }
        .fileCardWraperList {
          display: flex;
          .ListFileStatusWrapper {
            display: flex;
            justify-content: space-between;
            align-items: end;
          }
          .btnForList {
            margin-top: 3rem;
          }
          .ListFileCardTitle {
            font-size: small;
            margin-bottom: 1rem;
          }
          .ListDateSpan {
            display: flex;
            justify-content: start;
          }
        }
        .FileCardWrapperForList {
          min-width: 100%;
        }
      }
    }
  }
}
