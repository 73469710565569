.globalConditionsContainer {
  max-width: 100vw;
  padding: 20px;
  margin-top: 70px;

  .headerCountComponent {
    margin-left: 20px;
    display: flex;

    .green {
      background: $lightGreen;
      padding: 2px 4px;
      font-size: 15px;
      border-radius: 2px;
    }

    .red {
      background: $red;
      margin-left: 10px;
      padding: 2px 4px;
      font-size: 15px;
      border-radius: 2px;
    }
  }
  .addButton {
    font-size: 0.9rem;
    background-color: $lightBlue;
    border: none;
  }

  .addButton:focus {
    box-shadow: 0 0 0 0.25rem rgba(92, 107, 192, 0.3);
  }

  .clearButton {
    font-size: 0.9rem;
    background-color: $red;
    border: none;
    margin-top: 10px;
  }
  .clearButton:focus {
    box-shadow: 0 0 0 0.25rem rgba(255, 70, 81, 0.3);
  }
  .displayData {
    margin-top: 10px;
  }
  .updateButton {
    button {
      width: 20%;
      font-size: 0.9rem;
      background-color: $lightBlue;
      border: none;
    }

    // button:focus {
    // }
  }

  .customInputForGlobal {
    input {
      color: $white;
      background-color: $dark;
      padding: 0.375rem 0.75rem;
      font-size: 0.8125rem;
      font-weight: 400;
      line-height: 1.5;
    }
    label {
      font-size: 0.8125rem;
    }
  }
}
