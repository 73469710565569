// DragAndDropSelect.scss

// .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
//   color: #fff !important;
// }

// .css-bpeome-MuiSvgIcon-root-MuiSelect-icon {
//     color: #fff !important;
// }
// .css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
// }
.dragDropContext{
    // background-color: $dark !important;
    // background: $dark !important;
    // color: #fff !important;
    margin-right: 10px;

}
.customSelect {
  width: 100% !important;
  color: $white !important;
  background: $dark !important;
  border-radius: 0.25rem;
  border: 1px solid $blue4;
  padding: 6px 12px;
  height: 100%;

  label {
    margin-bottom: 3px;
  }

  select {
    width: 100% !important;
    color: $white !important;
    background: $dark !important;
    border-radius: 0.25rem;
    border: 1px solid $blue4;
    padding: 8px 12px;
    font-size: 0.8125rem;

    option {
      background: transparent;
    }
  }
}

// .css-6hp17o-MuiList-root-MuiMenu-list {
//   background: $dark !important;
// }

// Add other styles if needed
