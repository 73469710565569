.loginContainer {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  .loginCard {
    border-radius: 5px;
    min-width: 400px;
    width: 50%;
    padding: 15px;
    h2 {
      color: $lightBlue;
      font-weight: 500;
      text-transform: uppercase;
      text-align: center;
      font-size: 2rem;
    }
    h4 {
      color: $white;
      text-align: center;
      font-size: 1.5rem;
    }
    label {
      font-size: 15px;
    }
    input {
      margin-bottom: 10px;
      border-radius: 50px;
      color: $white;
    }
    input:focus {
      color: $black;
    }

    button {
      border-radius: 50px;
      width: 100%;
      background-color: $lightBlue;
      border: 1px solid transparent;
      height: 36px;
    }

    .circle {
      height: 150px;
      width: 150px;
      border-radius: 50%;
      background: rgba(0, 0, 0, 0.05);
      padding: 30px;
      img {
        height: 100%;
        width: 100%;
      }
    }
  }
}
