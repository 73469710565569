.offersContainer {
  max-width: 100%;
  margin-top: 70px;
  padding: 20px;
  
  .head {
    display: flex;
    align-items: center;

    button.green {
      background: $lightGreen;
      width: 100%;
      border: none;
      margin-right: 5px;
    }
    button.white {
      background: $white;
      color: $primary;
      width: 100%;
      border: none;
      margin: 0 5px;
    }
    .offersHeadInput {
      background-color: $blue3 !important;
    }
  }
  .optionsBar {
    padding: 10px;
    display: flex;
    justify-content: space-between;
    min-height: 55px;
    .deleteAll {
      background-color: $red;
      border-color: $red;
    }
    .pause {
      background-color: $yellow;
      border-color: $yellow;
    }
    .resume {
      background-color: $lightGreen;
      border-color: $lightGreen;
    }
    .btn {
      margin-right: 10px;
      font-weight: 400;
      font-size: 0.8125rem;
    }
    .btn:focus {
      box-shadow: none;
    }
  }
  .offers {
    .cardBody {
      padding: 0;
    }
  }
  table {
    border: none;

    color: $white;
    thead {
      tr {
        border-color: $blue5;
        background-color: $blue5;
        th {
          padding: 0.75rem;
        }
      }
      font-size: 1rem;
    }
    tbody {
      tr {
        border-color: #292d44;
        td {
          padding: 0.75rem;
          vertical-align: middle;
          font-weight: 400;
          font-size: 0.8125rem;
          .form-check-input:checked {
            background-color: $lightGreen;
            border-color: $lightGreen;
          }
          button {
            font-weight: 400;
            font-size: 0.8125rem;
          }
          button:focus {
            box-shadow: none;
          }
          .form-check-input {
            height: 20px;
            width: 40px;
            margin-right: 10px;
          }
          .form-check-input:focus {
            box-shadow: none;
          }
          .editButton-link {
            background-color: #0d6efd;
            border-color: #0d6efd;
            color: $white;
            padding: 0.375rem 0.75rem;
            border-radius: 0.375rem;
            text-decoration: none;
            margin-right: 10px;
          }
          .copyButton {
            background-color: $lightGreen;
            border-color: $lightGreen;
            margin-right: 10px;
          }
          .deleteButton {
            background-color: $red;
            border-color: $red;
          }
          .rejectionGraph {
            margin-left: 10px;
            margin-right: 10px;
          }
        }
      }
    }
  }
}

.automation-rules .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-1luety1-MuiAutocomplete-root .MuiAutocomplete-inputRoot{
  background-color:black;
  margin: 0;
  color:white
}

.automation-rules .MuiAutocomplete-hasPopupIcon.css-1luety1-MuiAutocomplete-root .MuiAutocomplete-inputRoot{
  margin:0
}
.automation-rules .css-aqpgxn-MuiFormLabel-root-MuiInputLabel-root{
  display: none;
}
.css-1dimb5e-singleValue{
  color:white !important
}

.automation-rules .css-qbdosj-Input {
  margin:0;
  padding:0
}
