.GeoTargetsContainer {
  width: 100%;
  margin-top: 70px;
  padding: 20px;
  .addCsvContainer {
    // display: flex;
    // justify-content: flex-end;
    // background-color: #0cce6b;
    display: flex;
    justify-content: end;
    .addCsvSubmitBtn {
      display: flex;
      justify-content: end;
    }
  }
  .head {
    display: flex;
    align-items: center;
    width: 100%;
    .headSubmitBtn {
      background: #0cce6b;
      border: none;
      margin-right: 5px;
      width: 100%;
    }
    .handleAddGeoTargetBtn {
      border: none;
      margin-right: 5px;
      width: 100%;
    }
    .GeoHeadInput{
      background-color: $blue3 !important;
    }
  }
  //in page component
  .actionWrapper {
    gap: 5px;
    .deleteButton {
      background-color: $red;
      border-color: $red;
    }
  }
}
.addEditGeoTargetsContainer {
  max-width: 100vw;
  padding: 10px;
  position: relative;
  margin-top: 60px;
}
.geoTargetsChildContainer {
  .cardBody {
    padding: 0;
  }
}
// .headerPart{
//     margin-top: 5rem;
// }
