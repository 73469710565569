.customCardContainer {
  .cover-all-collapse {
    background-color: black !important;
  }

  .headerComponent {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    font-size: 0.9rem;
    font-weight: bold;
    padding: 16px 20px;
    background-color: $lightBlue;
  }
  .cardBody {
    padding: 16px 20px;
    background-color: $blue2;
  }
}
