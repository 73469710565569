.customSpinner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px;
  .spinner-border {
    width: 3rem;
    height: 3rem;
  }
}

#customSpinnerForStatistics{
  padding: 5px;
  .spinner-border {
    width: 1rem;
    height: 1rem;
  }
}
