.ReportContainer {
  padding: 20px;
  margin-top: 70px;


  .export-filter-card {
    height: 85vh;
    background-color: $blue2;
  }

  .history-card {
    height: 85vh;
    overflow-y: scroll;
    background-color: $blue2;
  }
  .history-card::-webkit-scrollbar {
    width: 5px;
  }

  .history-card::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px $white;
  }

  .history-card::-webkit-scrollbar-thumb {
    background-color: #5c6bc0;
    outline: 1px solid #5c6bc0;
    border-radius: 5px;
  }

  .export-history-data-name h6 {
    color: $white;
    font-weight: bolder;
    word-wrap: break-word;
  }

  .status-bar {
    display: flex;
    align-items: center;
    margin: 2px;
  }

  .error {
    // border: solid 2px white;
    padding: 5px;
    background-color: crimson;
    margin-right: 5px;
  }

  .error-message {
    color: crimson;
  }

  .generated {
    padding: 5px;
    background-color: #0cce6b;
    margin-right: 5px;
  }

  .generated-button {
    padding: 5px;
    background-color: #0cce6b;
    color: $white;
  }

  .pending {
    padding: 5px;
    background-color: $yellow;
    margin-right: 5px;
  }

  .rdrDateRangePickerWrapper {
    display: flex;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    align-items: center;
    justify-content: space-center;
    max-width: 520px;
  }

  .rdrDateDisplayWrapper {
    background-color: $secondary;
    padding: 0 2em 0 2em;
  }

  // .ReportContainer .rdrDateDisplayWrapper {
  //   background-color: #222437;
  //   padding: 0 3em 0 2em;

  .rdrCalendarWrapper {
    color: #000000;
    font-size: 12px;
    background-color: $secondary;
    // width: 100%;
  }

  .rdrDefinedRangesWrapper {
    font-size: 12px;
    width: 226px;
  }

  .rdrDefinedRangesWrapper {
    font-size: 13px;
    width: 180px;
    border-right: none;
    background: $secondary;
  }

  .rdrMonths {
    display: flex;
    padding: 0 2em 0 2em;
  }

  .rdrStaticRange {
    cursor: pointer;
    color: white;
    background-color: #0d6efd;
    margin: 10px;
    border-radius: 10px;
    border: none;
    
    &:hover{
      color: #0d6efd;
      background-color: #ffffff;
    }
  }

  .rdrDefinedRangesWrapper .rdrStaticRangeSelected {
    background-color: darkblue;
    color: white;
    font-size: large;
  }

  .rdrStaticRangeLabel {
    text-align: center;
  }

  .rdrMonthAndYearPickers select {
    color: white;
    font-size: medium;
  }

  .rdrDateDisplayItem input {
    color: black;
    font-weight: 600;
    font-size: 15px;
  }

  .rdrDayNumber span {
    color: white;
  }

  .ReportContainer .rdrMonth {
    width: 20em;
    padding: 0 2em 1.666em 3em;
  }

  .rdrMonthAndYearPickers {
    flex: 1 1 auto;
    display: flex;
  }

  .rdrMonthAndYearWrapper {
    padding: 0 3em 0 2em;
    align-items: center;
  }
}
