.UsersContainer {
  max-width: 100vw;
  padding: 20px;
  margin-top: 70px;


  .header {
    // background-color: red;
    padding: 5px;
  }

  .button-container button {
    color: white;
    margin-left: 8px;
    font-size: 15px;
    padding: 5px;
    background-color: $lightBlue;
    border: none;
  }

  .button-container button.selectedUsersTab {
    background-color: $blue6;
  }

  Table {
    color: white;
  }

  Table thead {
    background-color: $primary;
    border: $secondary;
  }
  Table tbody {
    // background-color: $primary;
    border-bottom: 0.2px solid grey;
  }

  Table tr td {
    padding: 12px;
  }
}

.AddUserContainer {
  max-width: 45vw;
  padding: 20px;
}

.AddUserDhappa {
  max-width: 45vw;
  padding: 20px;
}

.addUserForm {
  background-color: $lightGreen;
}

.addUserForm:hover {
  background-color: $lightGreen;
}

.shoppingOfferModal {
  margin: 60px auto;
  border: none;
}
.AuditModal {
  margin: 60px auto;
  border: none;
  min-width: 700px;
}

.UserPopOverTable tr th {
  background-color: $primary;
  border: $secondary;
  color: white;
}
.UserPopOverTable tr td {
  background-color: $blue2;
  color: white;
}
.shoppingPopRows {
  cursor: pointer;
  background-color: red;
}
.shoppingpoprows:hover {
  cursor: pointer;
  background-color: $lightGreen;
}

.shoppingPopRows:active {
  background-color: yellow;
}

.userpopOverModalBody,
.UserPopOverTable-footer {
  background-color: $blue2;
  border-top: none;
  padding: 3px 8px;
}
.AuditModal-footer{
  width: 80vw;
}

.userpopOverModalBody {
  height: 40vh;
  overflow-y: scroll;
}
.AuditModalBody{
  height: 60vh;
  width:70vw;
  overflow-y: scroll;
}

.userpopOverModalBody::-webkit-scrollbar {
  width: 5px;
}

.userpopOverModalBody::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px $white;
}

.userpopOverModalBody::-webkit-scrollbar-thumb {
  background-color: black;
  outline: 1px solid black;
  border-radius: 5px;
}
