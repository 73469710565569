.audit-view-header {
  .header {
    background-color: $lightBlue;
    padding: 0 20px;
    border-radius: 3px;
    margin-top: 18px;
    .box {
      display: flex;
      align-items: center;
      justify-content: space-between;

      button {
        font-size: 0.8125rem;
        border: none;
      }
      .tabButton {
        background-color: transparent;
        margin-left: 5px;
        text-align: center;
        padding: 6px 12px;
        border-radius: 25px;
        height: 40px;
      }

      .selected {
        background-color: $blue6;
      }
    }
  }
}

#auditViewDifferenceInput {
  background: #ff9900 !important;
}

.redLineThrough {
  text-decoration: line-through red;
}

@keyframes slideInFromRight {
  from {
    width: 100px;
  }
  to {
    width: 500px;
  }
}

#history-drawer {  
  animation-name: slideInFromRight;
  animation-duration: .3s;
  animation-timing-function: ease-out;
}

@keyframes opacityIncrease {
  from{
    opacity: 0;
  }
  to{
    opacity: 100;
  }
}
 #opactiy-animation{
  
  animation-name: opacityIncrease;
  animation-duration: .3s;
  animation-timing-function: ease-out;
 }
