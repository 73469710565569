/* .App {
  width: 100%;
} */

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}
.navbarContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.wrapper {
  padding: 5px 10px;
}

.container-fluid {
  margin-top: 125px;
}

.row {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  margin-right: -7px;
  margin-left: -7px;
}

.card {
  background: #222437;
  box-shadow: 1px 1px 12px 1px rgb(0 0 0 / 6%);
  border: none;
  border-radius: 7px;
  position: relative;
  display: flex;
  flex-direction: column;
}

.card-header {
  background: #5c6bc0;
  padding: 1rem 1.25rem;
  margin-bottom: 0;
  display: flex;
}

.collapes_btn {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.card-title {
  margin-bottom: 0;
  padding-bottom: 0;
  font-weight: 500;
  cursor: pointer;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 10px;
  padding-bottom: 0;
  margin-bottom: 20px;
}

.inputs {
  width: 80%;
  display: flex;
  padding-top: 5px;
}

.label {
  padding-top: 20px;
  width: 80%;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  margin-left: 7px;
}

.input {
  width: 80%;
  padding: 7px;
  margin-top: 10px;
  border: none;
  border-radius: 5px;
  background: #12141d;
  outline: none;
  color: #fff;
  margin-left: 10px;
}

.button {
  width: 50px;
  height: 35px;
  padding: 7px;
  border: none;
  border-radius: 5px;
  background: #5c6bc0;
  color: #fff;
  outline: none;
  cursor: pointer;
  margin: 10px;
}

.button:hover {
  background-color: #0d6efd;
}

.display {
  padding-left: 10px;
  padding-right: 10px;
}

.display-data {
  margin-bottom: 112px;
  width: 100%;
  float: left;
  padding: 5px;
  margin-bottom: 20px;
  padding-top: 0;
  margin-top: 0.5rem;
}

.api-item {
  margin-right: 5px;
  cursor: pointer;
  padding: 6px;
  font-size: 13px;
  color: #fff;
  font-weight: 400;
  margin-bottom: 5px;
  background-color: #66bb6a;
  display: inline-block;
  border-radius: 5px;
}

.api-item-exclude {
  margin-right: 5px;
  cursor: pointer;
  padding: 6px;
  font-size: 13px;
  color: #fff;
  font-weight: 400;
  margin-bottom: 5px;
  background-color: #ff4651;
  display: inline-block;
  border-radius: 5px;
}

.x {
  background-color: #fff;
  text-shadow: none;
  opacity: 1;
  font-size: 11px;
  padding: 0;
  border-radius: 50%;
  height: 15px;
  width: 15px;
  margin-top: 2px;
  text-align: center;
  color: black;
  float: left;
  margin-top: 0;
  margin-right: 5px;
  margin-left: 0;
}

.d-button {
  width: 60px;
  height: 35px;
  padding: 7px;
  border: none;
  border-radius: 5px;
  background: rgb(245, 42, 42);
  color: #fff;
  outline: none;
  cursor: pointer;
  margin-top: 5px;
}

.update-cls {
  padding: 10px;
  display: flex;
  justify-content: center;
}

.update-btn {
  width: 200px;
  padding: 7px;
  border: none;
  border-radius: 5px;
  background: #5c6bc0;
  color: #fff;
  outline: none;
  cursor: pointer;
}

/* offer page css */

.flex-container {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.form-control {
  background-color: #252b50;
  border: none;
  width: 100% !important;
}

.custom-datepicker{
  width: 100% !important;
}

.search-btn {
  width: 100px;
  height: 35px;
  padding: 7px;
  border: none;
  position: relative;
  border-radius: 5px;
  background: #0cce6b;
  color: #fff;
  outline: none;
  cursor: pointer;
}

.add-offer-btn {
  width: 120px;
  height: 35px;
  padding: 7px;
  border: none;
  position: relative;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
}

.export-btn {
  width: 120px;
  height: 35px;
  padding: 7px;
  border: none;
  color: #0d6efd;
  position: relative;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
}

x:hover {
  color: #000;
}

#status {
  background-color: #252b50;
  border: none;
  color: #fff;
  width: 100px;
}

#type {
  background-color: #252b50;
  border: none;
  color: #fff;
}

.fixed-side {
  color: #fff;
}

/* add offer page css */

.nav-wrapper {
  width: 100%;
  position: fixed;
  top: 52px;
  z-index: 1;
  right: 0;
  left: 0;
}

.tabs-style {
  z-index: 99;
  background-color: #5c6bc0;
  width: 100%;
  padding: 10px;
  border-radius: 3px;
  flex-flow: row nowrap;
  justify-content: flex-start;
}

.navbar-nav a {
  padding: 6px 15px;
  text-decoration: none;
  color: #fff;
}
a.active {
  background-color: #1a1c2a;
  border-radius: 5px;
}

.align-end {
  text-align: end;
}

.add-button {
  width: 66px;
  height: 37px;
  padding: 7px;
  border: none;
  border-radius: 5px;
  background: #28a745;
  color: #fff;
  outline: none;
  cursor: pointer;
}

.cards-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 10px;
  padding-bottom: 0;
  margin-bottom: 100px;
}

/* tracking page css */

.remove-button {
  background-color: #5c6bc0;
  border: none;
  color: #fff;
}

.btn-condition {
  padding: 10px;
  float: right;
  background: #28a745;
  border: none;
  border-radius: 5px;
  color: #fff;
}
.btn-condition:hover {
  background-color: #0cce6b;
  color: #12141d;
}

.cards-body {
  padding-left: 20px;
  padding-right: 20px;
}

.field-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.add-container {
  display: flex;
}

.urlfield-container {
  justify-content: space-around;
  align-items: center;
  margin-left: 15px;
  margin-top: 10px;
}

.radio-container {
  justify-content: space-around;
  align-items: center;
  margin-left: 103px;
  margin-top: 12px;
}

.last-container {
  justify-content: space-around;
  align-items: center;
  margin-left: 12px;
  margin-top: 10px;
}

.last-input {
  width: 80%;
  padding: 7px;
  margin-top: 10px;
  border: none;
  border-radius: 5px;
  background: #12141d;
  outline: none;
  color: #fff;
  margin-left: 15px;
}

.urls-input1 {
  width: 73%;
  padding: 7px;
  margin-top: 10px;
  border: none;
  border-radius: 5px;
  background: #12141d;
  outline: none;
  color: #fff;
  margin-left: 10px;
}

.urls-input2 {
  width: 10%;
  padding: 7px;
  margin-top: 10px;
  border: none;
  border-radius: 5px;
  background: #12141d;
  outline: none;
  color: #fff;
  margin-left: 5px;
}

.urls-input3 {
  width: 7%;
  padding: 7px;
  margin-top: 10px;
  border: none;
  border-radius: 5px;
  background: #12141d;
  outline: none;
  color: #fff;
  margin-left: 5px;
}

.header-label {
  margin-top: 10px;
  margin-bottom: 0;
}

.header-select {
  width: 30%;
  padding: 7px;
  border: none;
  border-radius: 5px;
  background: #12141d;
  outline: none;
  color: #fff;
  margin-left: 5px;
}

.pt-container {
  justify-content: space-around;
  align-items: center;
  margin-left: 15px;
  margin-top: 10px;
}

.pt-input {
  width: 91%;
  padding: 7px;
  margin-top: 10px;
  border: none;
  border-radius: 5px;
  background: #12141d;
  outline: none;
  color: #fff;
  margin-left: 20px;
}

.pturl-input {
  width: 90%;
  padding: 7px;
  margin-top: 10px;
  border: none;
  border-radius: 5px;
  background: #12141d;
  outline: none;
  color: #fff;
  margin-left: 100px;
}

.addurl-btn {
  margin-left: 9px;
  padding: 5px;
  background-color: #0d6efd;
  border: none;
  border-radius: 5px;
  color: #fff;
}

.radio-input {
  display: none;
}

.radio-label {
  padding: 5px 15px;
  font-size: 15px;
  color: #fff;
  cursor: pointer;
  background: #12141d;
  transition: background 0.1s;
  border: 2px solid #fff;
}

.radio-label:not(:last-of-type) {
  border-right: 1px solid #fff;
}

.radio-input:checked + .radio-label {
  background: #5c6bc0;
}

.radio-include {
  display: none;
}

.radio-exclude {
  display: none;
}

.radio-include:checked + .radio-label1 {
  background: #28a745;
}

.radio-exclude:checked + .radio-label1 {
  background: #dc143c;
}

.radio-label1 {
  padding: 2px 10px;
  font-size: 15px;
  color: #fff;
  cursor: pointer;
  background: #12141d;
  transition: background 0.1s;
  border: 2px solid #fff;
}

.autoSearch {
  width: 80%;
}

.clear-btn {
  margin-top: 10px;
  margin-bottom: 10px;
}

.collapse {
  margin-top: 25px;
  margin-bottom: 10px;
}

.collapse-container {
  justify-content: space-around;
  align-items: center;
  margin-left: 103px;
  margin-top: 25px;
}

.collase {
  margin-left: 90px;
  margin-top: 10px;
}

/* Ganeral Page CSS */

.g-input {
  width: 100%;
  padding: 7px;
  margin-top: 10px;
  border: none;
  border-radius: 5px;
  background: #12141d;
  outline: none;
  color: #fff;
}

.main-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* adb-froud-detection */

.radio-item {
  margin-left: 10px;
}

.radio-meta {
  padding: 15px;
}

::-webkit-scrollbar {
  width: 1px;
  height: 0.5rem !important;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px #5c6bc0;
}

::-webkit-scrollbar-thumb {
  background-color: #ffffff;
  /* outline: 1px solid #5c6bc0; */
  border-radius: 5px;
}

/* SummaryModal.css */
.modal-container {
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transform: translateX(100%); /* Start off-screen to the right */
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.modal-container.open {
  opacity: 1;
  transform: translateX(0); /* Move to the center */
}


.react-datepicker {
  font-size: 12px;
  height:30;

}

.react-datepicker-wrapper {
  display: inline-block;
}

.react-datepicker__input-container {
  width: 100%;
  height:20
}

.react-datepicker__input-container input {
  width: 100%;
  padding: 4px;
  color:white;
  background-color: #252B50;
  border: 1px solid #252B50;
  border-radius: 4px;
  box-sizing: border-box;
  
}

.react-datepicker__input-container input:focus {
  outline: none;
  /* border-color: #007bff; */
  
}

.react-datepicker__triangle {
  display: none; /* Hide the triangle */
}

.react-datepicker-popper {
  z-index: 9999; /* Ensure it's above other elements */

}
.react-datepicker{
  background-color: #252B50;
  color:"white"
}