.customSelect {
  width: 100% !important;
  color: $white !important;
  background: $dark !important;
  border-radius: 0.25rem;
  border: 1px solid $blue4;
  padding: 6px 12px;
  height: 100%;
}
.visitingFrequencyCustomeSelect{
  input {
    height: 550px;
  }
}
.customSelectWithLabel {
  label {
    margin-bottom: 3px;
  }
  select {
    width: 100% !important;
    color: $white !important;
    background: $dark !important;
    border-radius: 0.25rem;
    border: 1px solid $blue4;
    padding: 8px 12px;
  }
  font-size: 0.8125rem;
}

.customMultiSelectWithLabel {
  label {
    margin-bottom: 3px;
  }
  .multi-select {
    .multi-select__control {
      width: 100% !important;
      color: $white !important;
      background: $dark !important;
      border-radius: 0.25rem;
      border: 1px solid $blue4;
      font-size: 1.2rem !important;
      font-weight: bold;

      .multi-select__value-container {
        .multi-select__input-container {
          color: $white;
        }
        .multi-select__multi-value {
          background: #cdcdcd40;
          .multi-select__multi-value__label {
            color: $white;
          }
          .multi-select__multi-value__remove {
            color: $red;
          }
        }
      }
    }
    
    .multi-select__menu {
      background: $dark !important;
      .multi-select__option--is-focused {
        background-color: $blue6;
      }
    }
  }

  font-size: 0.8125rem;
}
.customMultiSelectWithLabel2 {
  label {
    margin-bottom: 3px;
  }
  .multi-select {
    .multi-select__control {
      width: 100% !important;
      color: $white !important;
      background: $dark !important;
      border-radius: 0.25rem;
      border: 1px solid $blue4;

      .multi-select__value-container {
        .multi-select__input-container {
          color: $white;
        }
        .multi-select__multi-value {
          background: #cdcdcd40;
          .multi-select__multi-value__label {
            color: $white;
          }
          .multi-select__multi-value__remove {
            color: $red;
          }
        }
      }
    }
    
    .multi-select__menu {
      background: $dark !important;
      .multi-select__option--is-focused {
        background-color: $blue6;
      }
    }
  }

  font-size: 0.8125rem;
}

.custom-select {
  .custom-select__control {
    width: 100% !important;
    color: $white !important;
    background: $dark !important;
    border-radius: 0.25rem;
    border: 1px solid $blue4;

    .custom-select__value-container {
      .custom-select__input-container {
        color: $white;
      }
      .custom-select__single-value {
        color: $dark;
      }
      .custom-select__multi-value {
        background: transparent;
        font-size: 0.8125rem;

        border: 1px solid $white;
        .custom-select__multi-value__label {
          color: $white;
        }
        .custom-select__multi-value__remove {
          color: $red;
        }
      }
    }
  }
  .custom-select__menu {
    background: $dark !important;
    font-size: 0.8125rem;
    .custom-select__option--is-focused {
      background-color: $blue6;
    }
  }
}
 .multi-select__input{
  min-width: 120px !important;
 }
