.customCardCollapseContainer {
  .headerComponent {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    font-size: 0.9rem;
    font-weight: bold;
    padding: 16px 20px;
    background-color: $lightBlue;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    .arrowButton {
      background: transparent;
      border: none;
      padding: 0;
      font-size: 1rem;
      margin: 0;
    }
    .arrowButton:focus {
      box-shadow: none;
    }
  }

  .rejectedHeader {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    font-size: 0.9rem;
    font-weight: bold;
    padding: 16px 20px;
    background-color: $red;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .arrowButton {
      background: transparent;
      border: none;
      padding: 0;
      font-size: 1rem;
      margin: 0;
    }
    .arrowButton:focus {
      box-shadow: none;
    }
  }

  .collapse {
    margin: 0;
  }
  .cardBody {
    padding: 16px 20px;
    background-color: $blue2;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}
