.dashboardContainer {
  max-width: 100vw;
  padding: 10px;
  position: relative;
  margin-top: 60px;

  .header {
    background-color: $lightBlue;
    padding: 5px 20px;
    border-radius: 3px;
    .box {
      display: flex;
      align-items: center;
      justify-content: space-between;

      button {
        font-size: 0.8125rem;
        border: none;
      }
      .tabButton {
        background-color: transparent;
        margin-left: 5px;
      }

      .selected {
        background-color: $blue6;
      }

      .submit {
        background-color: $lightGreen;
      }
    }
  }

  /* Chip.css */
.chip {
  display: inline-flex;
  align-items: center;
  background-color: #141721;
  border-radius: 5px;
  padding: 6px 12px;
  margin: 4px;
  font-size: 14px;
}

.chip-label {
  margin-right: 8px;
}

.chip-close-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 14px;
  color: #ff0000;
}


  .row {
    margin-left: 0;
    margin-right: 0;
    display: flex;
  }

  .card1Button {
    background-color: $lightGreen;
    border: none;
    margin: 5px;
    flex-grow: 1;
  }
  .card1Button:focus {
    box-shadow: none;
  }
}
