$primary: #12141d;
$secondary: #222437;
$lightBlue: #5c6bc0;
$blue2: #222437;
$blue3: #252b50;
$blue4: #202334;
$blue5: #1a1c2a;
$blue6: #374073;
$dark: #12141d;
$white: #fff;
$black: #000;
$lightGreen: #0cce6b;
$red: #ff4651;
$yellow: #ffa500;
