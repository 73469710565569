.sticky-top {
  position: sticky;
  top: 50px;
  left: 0px;
  right: 0px;

  // .scrollBar {
  //   &::-webkit-scrollbar {
  //     width: 4px;
  //     visibility: hidden;
  //     height: 0.5rem !important;
  //   }
  //   & {
  //     scrollbar-width: auto;
  //     scrollbar-color: #f8f9fa #5c6bc0;
  //     border-radius: 0.5rem;
  //   }
  // }
}
.relative-class {
  position: relative;
  input {
    background-color: #12141d;
    color: #fff;
    width: 100%;
    padding: 9px;
    border-radius: 5px;
    border: none;
    font-weight: 700;
    outline: none;
  }
  .date-range-picker-wrapper {
    position: absolute;
    top: 100%;
    left: 10px;
    z-index: 10;
  }
}
.addEditOfferContainer {
  margin-top: 40px;
  .header {
    background-color: $lightBlue;
    padding: 5px 20px;
    border-radius: 3px;
    margin-top: 18px;
    .box {
      display: flex;
      align-items: center;
      justify-content: space-between;

      button {
        font-size: 0.8125rem;
        border: none;
      }
      .tabButton {
        background-color: transparent;
        margin-left: 5px;
        text-align: center;
        padding: 6px 12px;
        border-radius: 25px;
        height: 40px;
      }
      .Draggable {
        display: flex;
        width: "100%";
        border: 1px dashed #f8f9fa;
        &:hover {
          border: 2px dashed #f8f9fa;
        }
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently*/
      }
      .OfferIdButton {
        background-color: transparent;
        margin-left: 15px;
        cursor: inherit;
      }

      .selected {
        background-color: $blue6;
      }

      .submit {
        background-color: $lightGreen;
      }
    }
  }
  .body {
    padding: 20px;
    padding-top: 0px;
    .generalContainer {
      .customSelectWithLabel {
        select {
          height: 33px;
        }
      }
      .customInputWithLabel {
        input {
          height: 33px;
        }
      }
    }
    .condition {
      .customCardCollapseContainer {
        .cardBody {
          padding: 0px 10px 10px 10px !important;
        }
      }
      .subTabBar {
        position: sticky;
        top: 118px;
        left: 0px;
        right: 0px;
        z-index: 1000;
      }
    }

    .addConditionButton {
      font-size: 0.8125rem;
      background-color: $lightGreen;
      border: none;
    }
    .addConditionButton:focus {
      box-shadow: none;
    }
    .customInput {
      font-size: 0.8125rem;
      height: 40px;
    }
    .visiting-option {
      height: 40px !important;
    }
    .customInput::placeholder {
      font-size: 0.8125rem;
    }
    .UrlsInputs {
      display: flex;
      align-items: end;
      margin-bottom: 8px;
      .inputsEnd {
        width: 150px !important;
        margin-left: 10px;
      }
    }
    .buttonNoBackground {
      background: transparent;
      border: none;
      color: $white;
      .add-icon {
        font-size: 25px;
        color: $lightGreen;
      }
      .minus-icon {
        font-size: 20px;
        color: $red;
      }
    }

    .buttonNoBackground:disabled {
      opacity: 0.2;
    }

    .conditionHead {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .conditionText {
        vertical-align: middle;
      }
      button {
        margin-right: 5px;
      }
    }
    .header {
      .tab-scroll {
        max-width: 75%;
        overflow-y: hidden;
        overflow-x: auto;
      }

      .tab-scroll::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(202, 202, 202, 0.3);
        border-radius: 10px;
        background-color: transparent;
        display: none;
      }

      .tab-scroll::-webkit-scrollbar {
        width: 4px;
        background-color: transparent;
      }

      .tab-scroll::-webkit-scrollbar-thumb {
        border-radius: 4px;
        -webkit-box-shadow: inset 0 0 6px rgba(183, 183, 183, 0.3);
        background-color: rgb(201, 201, 201);
      }
    }

    .othersContainer {
      .othersVisitingFrequencyInputs {
        font-size: 0.8125rem;
        label {
          margin-bottom: 5px;
        }
        .row {
          margin-top: 0;
        }
        input {
          height: auto;
        }
      }

      .resolutionComponentInput {
        font-size: 0.8125rem;
        label {
          margin-bottom: 5px;
        }
        .inputContainer {
          display: flex;
          align-items: center;
          input {
            width: 250px !important;
          }
          .addButton {
            font-size: 0.8125rem;
            margin-left: 25px;
          }
          .addButton:focus {
            box-shadow: 0 0 0 0.25rem rgba(92, 107, 192, 0.3);
          }
        }
      }
    }
  }
}
