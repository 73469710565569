.customInput {
  width: 100% !important;
  color: $white !important;
  background: $dark !important;
  border-radius: 0.25rem;
  border: 1px solid $blue4;
  padding: 6px 12px;
  height: 100%;

  &::placeholder {
    color: $white; // Change placeholder color to white
  }

  &::-webkit-calendar-picker-indicator {
    filter: invert(1); // Invert the color of the calendar icon to white
  }

  &::-webkit-inner-spin-button {
    filter: invert(1); // Invert the color of the inner spin button icon to white
  }
}

.customInput::placeholder {
  color: $white;
  opacity: 0.8;
}

.customInputWithLabel {
  width: 100% !important;
  font-size: 0.8125rem;
  label {
    margin-bottom: 5px;
  }
  input {
    width: 100% !important;
    color: $white !important;
    background: $dark !important;
    border-radius: 0.25rem;
    border: 1px solid $blue4;
    padding: 6px 12px;
  }
}

.customInputForG {
  font-size: 0.8125rem;
  label {
    margin-bottom: 5px;
  }
  input {
    font-size: 0.8125rem;
    width: 100% !important;
    color: $white !important;
    background: $dark !important;
    border-radius: 0.25rem;
    border: 1px solid $blue4;
    padding: 6px 12px;
  }
}
.CustomModuleInput {
  .addButton {
    font-size: 0.8125rem;
  }
  .addButton:focus {
    box-shadow: 0 0 0 0.25rem rgba(92, 107, 192, 0.3);
  }
  .clearButton {
    font-size: 0.8125rem;
    background-color: $red;
    border: none;
    margin: 10px 0;
  }
  .clearButton:focus {
    box-shadow: 0 0 0 0.25rem rgba(255, 70, 81, 0.3);
  }
}
.clearButton {
  font-size: 0.8125rem;
  background-color: $red;
  border: none;
  margin: 10px 0;
}

.IncludeExcludeInputModule {
  .IncludeExcludeInputModuleLabel {
    display: flex;
    font-size: 1rem;
    .count {
      margin-left: 20px;
      .includeActive {
        background: #28a745;
        padding: 0 3px;
      }
      .excludeActive {
        padding: 0 3px;
        background: #dc143c;
      }
    }
  }
  .radioButtonContainer {
    margin-top: 10px;

    .includeActive {
      background: #28a745;
      padding: 2px 10px;
      font-size: 0.8125rem;
      color: #fff;
      transition: background 0.1s;
      border: 2px solid #fff;
      border-radius: 0;
    }
    .normal {
      padding: 2px 10px;
      font-size: 0.8125rem;
      color: #fff;
      cursor: pointer;
      background: #12141d;
      transition: background 0.1s;
      border: 2px solid #fff;
      border-radius: 0;
    }

    .excludeActive {
      background: #dc143c;
      padding: 2px 10px;
      font-size: 0.8125rem;
      color: #fff;
      transition: background 0.1s;
      border: 2px solid #fff;
      border-radius: 0;
    }
  }

  .clearButton {
    font-size: 0.9rem;
    background-color: $red;
    border: none;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .clearButton:focus {
    box-shadow: 0 0 0 0.25rem rgba(255, 70, 81, 0.3);
  }
}

.IncludeExcludeInputModuleType2 {
  .IncludeExcludeInputModuleType2Label {
    display: flex;
    font-size: 1rem;
    .count {
      margin-left: 20px;
      display: flex;

      .includeActive {
        background: #28a745;
        max-height: 24px;
        padding: 0 3px;
      }
      .excludeActive {
        max-height: 24px;
        padding: 0 3px;
        background: #dc143c;
        margin-left: 10px;
      }
    }
    // .allow-non-blank {
    //   // background-color: red;
    //   margin-top: -2.3rem;
    // }
  }
  .radioButtonContainer {
    margin-top: 10px;

    .includeActive {
      background: #28a745;
      padding: 2px 10px;
      font-size: 0.8125rem;
      color: #fff;
      transition: background 0.1s;
      border: 2px solid #fff;
      border-radius: 0;
    }
    .normal {
      padding: 2px 10px;
      font-size: 0.8125rem;
      color: #fff;
      cursor: pointer;
      background: #12141d;
      transition: background 0.1s;
      border: 2px solid #fff;
      border-radius: 0;
    }

    .excludeActive {
      background: #dc143c;
      padding: 2px 10px;
      font-size: 0.8125rem;
      color: #fff;
      transition: background 0.1s;
      border: 2px solid #fff;
      border-radius: 0;
    }
  }
  .display-data-include {
    width: 100%;
    float: left;
    margin-top: 0.5rem;
  }
  .display-data-exclude {
    width: 100%;
    float: left;
    margin-bottom: 15px;
    margin-top: 0.4rem;
  }

  .clearButton {
    font-size: 0.8125rem;
    max-height: 30px;
    background-color: $red;
    border: none;
    margin-top: 10px;
    // margin-bottom: 10px;
  }
  .clearButton:focus {
    box-shadow: 0 0 0 0.25rem rgba(255, 70, 81, 0.3);
  }
}

.IncludeExcludeTypeInput {
  font-size: 0.8125rem;
  input {
    font-size: 0.8125rem;
    width: 100% !important;
    color: $white !important;
    background: $dark !important;
    border-radius: 0.25rem;
    border: 1px solid $blue4;
    padding: 6px 12px;
  }
}
